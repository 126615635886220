import React from "react";
import { Box, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangePasswordForm = ({ onSubmit, onCancel }) => {
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      <TextField
        fullWidth
        margin="normal"
        name="currentPassword"
        label="Current Password"
        type="password"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.currentPassword &&
          Boolean(formik.errors.currentPassword)
        }
        helperText={
          formik.touched.currentPassword && formik.errors.currentPassword
        }
      />
      <TextField
        fullWidth
        margin="normal"
        name="newPassword"
        label="New Password"
        type="password"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
      />
      <TextField
        fullWidth
        margin="normal"
        name="confirmNewPassword"
        label="Confirm New Password"
        type="password"
        value={formik.values.confirmNewPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmNewPassword &&
          Boolean(formik.errors.confirmNewPassword)
        }
        helperText={
          formik.touched.confirmNewPassword && formik.errors.confirmNewPassword
        }
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Change Password
      </Button>
      <Button fullWidth variant="outlined" onClick={onCancel} sx={{ mb: 2 }}>
        Cancel
      </Button>
    </Box>
  );
};

export default ChangePasswordForm;
